import * as React from "react"
import { Hero } from "../components/hero"
import { StaticImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import { BigLinks } from "../components/big-links"
import { ContactLink } from "../components/contact-link"
import { Newsletter } from "../components/newsletter"

interface ContactProps {
  pageContext: any
  data: any
}

export default function ContactPage({ pageContext, data }: ContactProps) {
  const { i18n } = pageContext
  const {
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          instagram
          facebook
          # phone
          # phoneHumanFormat
          email
        }
      }
    }
  `)

  const links = [
    {
      text: i18n.contact.email,
      href: `mailto:${siteMetadata.email}`,
      hrefText: siteMetadata.email,
      justCopied: false,
    },
    // {
    //   text: i18n.contact.phone,
    //   href: `tel:${siteMetadata.phone}`,
    //   hrefText: siteMetadata.phoneHumanFormat,
    //   justCopied: false,
    // },
    {
      text: i18n.contact.facebook,
      href: siteMetadata.facebook,
      hrefText: siteMetadata.facebook,
      justCopied: false,
    },
    {
      text: i18n.contact.instagram,
      href: siteMetadata.instagram,
      hrefText: siteMetadata.instagram,
      justCopied: false,
    },
  ]

  return (
    <>
      <Hero title={i18n.contact.title}>
        <StaticImage
          className="!absolute z-0 w-full h-full"
          src="../assets/images/banner_split.jpg"
          objectPosition="70% 50%"
          alt=""
          quality={95}
        />
      </Hero>
      <section className="mt-20 out-wrapper">
        <ul className="text-xl">
          {links.map((link) => (
            <ContactLink
              key={link.href}
              text={link.text}
              href={link.href}
              hrefText={link.hrefText}
              i18n={i18n}
            />
          ))}
        </ul>
        <BigLinks i18n={i18n} isContactPage={true} />
      </section>
      <Newsletter i18n={i18n} />
    </>
  )
}
