import React, { useState } from "react"
import { FiCopy } from "@react-icons/all-files/fi/FiCopy"

interface ContactLinkProps {
  text: string
  href: string
  hrefText: string
  i18n: any
}

export function ContactLink({ text, href, hrefText, i18n }: ContactLinkProps) {
  const [justClicked, setJustClicked] = useState(false)

  return (
    <li className="mb-5 sm:pl-16" key={href}>
      <button
        className={`mr-2 relative`}
        title={i18n.contact.clickToCopy}
        onClick={() => {
          setJustClicked(true)
          setTimeout(() => {
            setJustClicked(false)
          }, 500)
          navigator.clipboard.writeText(href)
        }}
      >
        <span
          aria-hidden="true"
          className={`${
            justClicked ? "opacity-1 -top-6 -rotate-6" : "opacity-0 -top-3"
          } absolute left-0 transition-all duration-500 text-sm`}
        >
          {i18n.contact.copied}
        </span>
        <FiCopy
          className={`${
            justClicked ? "scale-[1.15] rotate-6" : ""
          } w-6 h-6 transition-transform	duration-300`}
        />
      </button>
      <span className="font-bold">{text}</span>
      <a href={href}>{hrefText}</a>
    </li>
  )
}
